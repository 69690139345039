<template>
    <b-container>
                <b-row align-v="stretch" class="full-height justify-content-md-center">
                <b-col lg="6" md="8" style="bottom: 125px">
                    <b-card tag="article" class="v-center flex-column" no-body align="left">
                        <b-col cols="12" offset="12" class="text-right mt-1" style="font-size: 1.3em; background: white">
                            <a :class="language === 'en' ? 'font-bold text-primary mr-4' : 'mr-4 text-primary'"
                               @click="changeLanguage('en')">en</a>
                            <a :class="language === 'es' ? 'font-bold text-primary' : 'text-primary'"
                               @click="changeLanguage('es')">es</a>
                        </b-col>
                        <b-card-body style="background: white">
                            <b-row class="justify-content-md-center">
                                <h1>{{ isAdmin ? $t('adminSupplierPortal') : '' }} {{ $t('supplierPortal') }}</h1>
                                <b-col lg="12" class="mt-3">
                                    <div class="my-auto mx-auto text-center">
                                        <img src="../assets/img/logo.png" alt="Login" style="width: 50%">
                                    </div>
                                </b-col>
                            </b-row>
                            <b-row class="mt-3">
                                <b-col cols="12" md="12" lg="12">
                                    <ValidationObserver ref="observer" v-slot="{ passes }">
                                        <b-form @submit.prevent="passes(onSubmit)">
                                            <ValidationProvider rules="usernameRequired" name="Username" v-slot="{ valid, errors }">
                                                <b-input-group>
                                                    <template v-slot:prepend>
                                                        <b-input-group-text >
                                                            <font-awesome-icon icon="user"></font-awesome-icon>
                                                        </b-input-group-text>
                                                    </template>

                                                    <b-form-input v-model="username" :placeholder="$t('username')" autocomplete="off"
                                                                  :state="(errors[0] || loginError) ? false : (valid ? true : null)"></b-form-input>
                                                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                                </b-input-group>
                                            </ValidationProvider>

                                            <ValidationProvider rules="passwordRequired" name="Password" v-slot="{ valid, errors }">
                                                <b-input-group class="mt-4">
                                                    <template v-slot:prepend>
                                                        <b-input-group-text >
                                                            <font-awesome-icon icon="lock"></font-awesome-icon>
                                                        </b-input-group-text>
                                                    </template>
                                                    <b-form-input v-model="password" :placeholder="$t('password')" type="password"
                                                                  :state="(errors[0] || loginError) ? false : (valid ? true : null)"></b-form-input>
                                                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                                </b-input-group>
                                            </ValidationProvider>
											<ValidationProvider v-if="isAdmin" rules="supplierIdRequired" name="SupplierId" v-slot="{ valid, errors }">
												<b-input-group class="mt-4">
													<template v-slot:prepend>
														<b-input-group-text >
															<font-awesome-icon icon="id-card"></font-awesome-icon>
														</b-input-group-text>
													</template>
													<b-form-input v-model="supplierId" :placeholder="$t('supplierId')"
                                                        :state="(errors[0] || loginError) ? false : (valid ? true : null)"></b-form-input>
													<b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
												</b-input-group>
											</ValidationProvider>

                                            <b-row class="mt-2 text-center" v-show="errorMessage">
                                                <b-col class="text-danger">{{ errorMessage }}</b-col>
                                            </b-row>

                                            <b-row class="mt-4">
                                                <b-col cols="12" offset="12" class="text-center">
                                                    <b-button type="submit" variant="primary" class="mx-auto" block :disabled="loggingIn">
                                                        <template v-if="loggingIn"><b-spinner small></b-spinner></template>
                                                        <template v-else>{{ $t('login') }}</template>
                                                    </b-button>
                                                </b-col>
                                                <b-col cols="12" offset="12" class="text-center mt-3">
                                                    <h5>{{ $t('notHaveAnAccount') }}</h5>
                                                    <b-button type="button" :to="{name: 'register'}" variant="outline-primary" class="mx-auto">
                                                        {{ $t('signUp') }}
                                                    </b-button>
                                                </b-col>
                                            </b-row>
                                        </b-form>
                                    </ValidationObserver>
                                </b-col>
                            </b-row>
                        </b-card-body>
                    </b-card>
                </b-col>
            </b-row>
        </b-container>
</template>

<script>
import { extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import { loginUrl } from '@routes';
import * as constants from '@constants';

export default {
    data() {
        return {
            username: '',
            password: '',
            supplierId: '',
            rememberMe: true,
            loggingIn: false,
            hasError: false,
            errorMessage: false,
            loginError: false,
            language: 'es'
        };
    },
    props: {
        isAdmin: {
            type: Boolean,
            default: false
        }
    },
    created() {
        let token = JSON.parse(localStorage.getItem('token'));
        let user = JSON.parse(localStorage.getItem('user'));
        
        if (!token && user) {
            this.errorMessage = this.$t('inactiveAccount');
        }
        
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.removeItem('userAction');
        
        if (localStorage.getItem('language')) {
            this.language = localStorage.getItem('language');
        } else {
            localStorage.setItem('language', this.language);
        }
        
        if (this.$route.params.message) {
            this.$bvToast.toast(this.$route.params.message, {
                title: 'Login',
                variant: 'success'
            });
        }
        
        extend('usernameRequired', {
            ...required,
            message: this.$t('requiredField')
        });
        
        extend('passwordRequired', {
            ...required,
            message: this.$t('requiredField')
        });

        extend('supplierIdRequired', {
            ...required,
            message: this.$t('requiredField')
        });
    },
    methods: {
        onSubmit() {
            this.loggingIn = true;
            this.errorMessage = false;
            this.loginError = false;
            let $this = this;

            this.axios.post(loginUrl(), {
                username: this.username.trim(),
                password: this.password.trim(),
                supplierId: this.supplierId.trim()
            }).then(response => {
                if (response.data.token) {
                    localStorage.setItem('token', JSON.stringify(response.data.token));
                    localStorage.setItem('user', JSON.stringify(response.data.user));
                    localStorage.setItem('sessionUser', JSON.stringify(response.data.sessionUser));
                    localStorage.setItem('language', $this.language);
                    localStorage.setItem('userAction', 'EDIT');

                    if (response.data.user.verified || (!response.data.user.verified && response.data.user.status === 'TO_VERIFY')) {
                        this.$router.push({ name: 'Home' });
                    } else if (!response.data.user.verified && response.data.user.status === 'TO_UPDATE') {
                        this.$router.push({ name: 'Account' });
                    } else {
                        this.$router.push({ name: 'register' });
                    }

                } else {
                    if (response.data.message === 'INCORRECT_USERNAME_PASSWORD') {
                        this.errorMessage = this.$t('incorrectUsernamePassword');
                    } else if (response.data.message === 'INACTIVE_ACCOUNT') {
                        this.errorMessage = this.$t('inactiveAccount');
                    } else {
                        this.errorMessage = this.$t('DEFAULT_ERROR_MESSAGE');
                    }
                }
            }).catch(error => {
                this.errorMessage = this.$t('DEFAULT_ERROR_MESSAGE');
            }).finally(() => {
                this.loggingIn = false;
            });
        },
        changeLanguage(value) {
            this.language = value;
            localStorage.setItem('language', this.language);
            location.reload();
        }
    }
};
</script>

<style>
    .v-center {
        margin-top: 30vh;
    }
</style>